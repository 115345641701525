import {WORKS_CONSTS} from "../constants";

export const initialState = {
    worksData:{
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    workById: null,
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case WORKS_CONSTS.SET_WORKS_DATA:
            return {
                ...state,
                worksData: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.worksData.itemsList, ...action.payload.data],
                    category: action.payload.category,
                    hasMore: action.payload.hasMore
                },
            };
        case WORKS_CONSTS.SET_WORKS_BY_ID:
            return {
                ...state,
                workById: action.payload || [],
            };
        case WORKS_CONSTS.DELETE_WORK_ITEM:
            return {
                ...state,
                worksData: {
                    ...state.worksData,
                    itemsList: state.worksData.itemsList.filter(product => product.id !== action.payload),
                    count: state.worksData.count - 1
                } ,
            };

        default:
            return state;
    }
}
