import {request, _urlBlocks} from "../api";
import {BLOCKS_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";

export const GetBlocks = () => {
    const requestData = {
        url: _urlBlocks,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BLOCKS_CONSTS.BLOCKS_GET,
                    payload: data
                });
            })
    }
};

export const GetBlocksById = (id) => {
    const requestData = {
        url: `${_urlBlocks}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BLOCKS_CONSTS.BLOCKS_GET_BY_ID,
                    payload: data
                });
            })
    }
};

export const UpdateBlocksById = (id, data) => {
    const requestData = {
        url: `${_urlBlocks}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BLOCKS_CONSTS.BLOCKS_UPDATE_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Բլոկը հաջողությամբ փոփոխվեց!"
                })
                history.push('/dashboard')
            })
            .catch(() => {
            })
    }
};