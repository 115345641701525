import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import utils from "./utils";
import languages from "./languages";
import moderators from "./moderators";
import staticTexts from "./staticTexts";
import media from "./media";
import composite from "./composite";
import slider from "./slider";
import partner from "./partner";
import general from "./general";
import subscribers from "./subscribers";
import about from "./about";
import services from "./services";
import works from "./works";
import blocks from "./blocks";
import project from "./project";
import sponsors from "./sponsors";



export default combineReducers({
    auth,
    utils,
    languages,
    media,
    composite,
    moderators,
    staticTexts,
    slider,
    partner,
    general,
    subscribers,
    about,
    services,
    works,
    blocks,
    project,
    sponsors,
});
