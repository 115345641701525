import {request, _urlWorks} from "../api";
import {UTIL_CONSTS, WORKS_CONSTS} from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import { LIMIT_GET_WORK} from "../../constants/constType";


export const GetWorksData = ({
                                reset = true,
                                offset = 0,
                                category,
                                limit = LIMIT_GET_WORK,
                            } = {}) => {
    let url = `${_urlWorks}?offset=${offset}&limit=${limit}`;
    category && (url += `&category=${category}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                data && dispatch({
                    type: WORKS_CONSTS.SET_WORKS_DATA,
                    payload: {
                        data,
                        category,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetWorksById = (id) => {
    const requestData = {
        url: `${_urlWorks}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: WORKS_CONSTS.SET_WORKS_BY_ID,
                    payload: data
                });
            })
    }
};

export const CreateWorkItem = data => {
    const requestData = {
        url: _urlWorks,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: WORKS_CONSTS.CREATE_WORK_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Աշխատանքը հաջողությամբ ստեղծվեց!"
                })
                history.push('/works')
            })
            .catch(() => {
            })
    }
};

export const UpdateWorksItemData = (id, data) => {
    const requestData = {
        url: `${_urlWorks}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: WORKS_CONSTS.UPDATE_WORK_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Աշխատանքը հաջողությամբ փոփոխվեց!"
                })
                history.push('/works');
                return res.data
            })
    }
};

export const DeleteWorkItem = id => {
    const requestData = {
        url: `${_urlWorks}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: WORKS_CONSTS.DELETE_WORK_ITEM,
                    payload: id
                });
            })
    }
};
