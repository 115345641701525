// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../assets/styles/containerStyles/contact.scss";

// Import components
import {InputGroup} from "../components/uiElements/inputGroup";
import PageWrapper from '../components/pageContentViews/pageWrapper'
import {LinkButton} from "../components/buttons/buttons";
import {LanguageTabs} from "../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetContact, UpdateContact} from "../redux/actions";
import {ContactAddingList} from "../constants/contactAddingList";
import {
    changeStateField,
    getInputValues,
    initTranslations,
    getTranslatableInputValues, getTranslationData, toggleStateField
} from "../utils/helperFunctions";
import {validateTranslations} from "../utils/validateTranslations";
import MediaSelectorModal from "../components/media/MediaSelectorModal";


export class Contact extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'address',
            'title',
            'description',
            'pdf',
            'presentationFile',
        ]
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                email: "",
                phoneNumber: "",
                phoneNumber2: "",
                latitude: 0,
                longitude: 0,
            },
            isEditing: true,
            errorsTabs: [],
            errors: {},
            languageTab: props.mainLanguage,
            mediaModalOpen: false,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.togglePdfModal = toggleStateField.bind(this, 'pdfModalOpen');
        this.toggleFileModal = toggleStateField.bind(this, 'fileModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.getPdf = this.getPdf.bind(this);
        this.getFile = this.getFile.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.updateContact = this.updateContact.bind(this);
    }


    async componentDidMount() {
         await this.props.GetContact();
        const {contactData} = this.props;
        const {fieldsData} = this.state;

        const translationsData = {};
        Object.keys(fieldsData.translations).forEach(key => {
            const trItem = contactData?.translations?.find(item => item.languageId === key);
            translationsData[key] = {
                ...fieldsData.translations[key],
                address: trItem?.address || '',
                title: trItem?.title || '',
                description: trItem?.description || '',
                pdf: trItem?.pdf || '',
                presentationFile: trItem?.presentationFile || '',
            };
        });
        contactData && this.setState({
            fieldsData: {
                email: contactData.email || "",
                phoneNumber: contactData.phoneNumber || "",
                phoneNumber2: contactData.phoneNumber2 || "",
                latitude: contactData?.latitude || "",
                longitude: contactData?.longitude || "",
                translations: translationsData
            },
        });
    }


    validateFields(constacData) {
        const translationRequiredFields = ['address','title', 'description',];
        const validationTr = validateTranslations(constacData.translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        const phoneNumberRegExp = /^[+]?[0-9]{9,20}$/;
        if (constacData?.phoneNumber?.length && !phoneNumberRegExp.test(constacData.phoneNumber)) {
            errors.phoneNumber = true;
            result = false;
        }
        if (constacData?.phoneNumber2?.length && !phoneNumberRegExp.test(constacData.phoneNumber2)) {
            errors.phoneNumber2 = true;
            result = false;
        }
        if (!result) {
            this.setState({
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
        return result;
    }

    getPdf(pdf) {
        const {fieldsData,languageTab} = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        pdf: pdf[0],
                    }
                }
            },
        })
    }
    getFile(file) {
        const {fieldsData,languageTab} = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        presentationFile: file[0],
                    }
                }
            },
        })
    }

    updateContact() {
        const {fieldsData} = this.state;
        const {translations} = fieldsData;
        if (this.validateFields(fieldsData)) {
            let TR_data = getTranslationData(translations);

            let reqData = {
                email: fieldsData.email,
                phoneNumber: fieldsData.phoneNumber,
                phoneNumber2: fieldsData.phoneNumber2,
                latitude: fieldsData?.latitude,
                longitude: fieldsData?.longitude,
                translations: TR_data,
            };
            // if (pdf) reqData.pdf = pdf?.id;
            // if (presentationFile) reqData.presentationFile = presentationFile?.id;
            this.props.UpdateContact(reqData).then(() => {
                this.updatedDataMap.clear();
                this.setState({
                    errors: {},
                    errorsTabs: []
                })
            })
        }
    }

    render() {
        const {requestLoading, contactData: contact} = this.props;
        const {fieldsData, languageTab, errors, errorsTabs,pdfModalOpen,fileModalOpen} = this.state;

        const {translations} = fieldsData;
        const initTranslations = contact?.translations?.find(lg => lg.languageId === languageTab) || {};

        const trData = translations[languageTab] || {};
        return <PageWrapper pageTitle={'Կապ'}>
            <section className="contact">

                <div className="left-part">
                    <InputGroup inputType="input"
                                type="text"
                                label="Լայնություն"
                                placeholder="Լայնություն"
                                regExp={/^[0-9]*(\.)?[0-9]*$/}
                                maxValue={90}
                                minValue={-90}
                                name="latitude"
                                required={true}
                                value={fieldsData?.latitude}
                                error={errors['latitude']}
                                onChange={this.getInputValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Երկայնություն"
                                placeholder="Երկայնություն"
                                regExp={/^[0-9]*(\.)?[0-9]*$/}
                                maxValue={180}
                                minValue={-180}
                                name="longitude"
                                required={true}
                                value={fieldsData?.longitude}
                                error={errors['longitude']}
                                onChange={this.getInputValues}/>
                </div>
                <div className="info-adding-fields">
                    {ContactAddingList.slice(0, 5).map((item, index) => {
                        return <InputGroup key={index}
                                           {...item}
                                           error={errors[item.name]}
                                           value={fieldsData?.[item.name]}
                                           initValue={contact?.[item.name]}
                                           onChange={this.getInputValues}/>
                    })}
                    <div className="social-links">
                        <label>Սոցիալական հղումներ</label>
                        {ContactAddingList.slice(5).map((item, index) => {
                            return <InputGroup key={index}
                                               {...item}
                                               error={errors[item.name]}
                                               value={fieldsData?.[item.name]}
                                               initValue={contact?.[item.name]}
                                               onChange={this.getInputValues}/>
                        })}
                    </div>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className={'label-and-action'}>
                    <label>Կցել PDF</label>
                    <LinkButton className={"media-select-btn bg-white"}
                                title={"Ընտրել PDF ֆայլ"}
                                cb={this.togglePdfModal}/>
                </div>
                <div className={'contract-wrapper'}>
                    <div className={'wrapper'}>
                        <span>Անվանում՝ {fieldsData.translations[languageTab]?.pdf?.name || initTranslations?.pdf?.name}</span>
                    </div>
                </div>
                <div className={'label-and-action file-wrapper'}>
                    <label>Կցել ներկայացման ֆայլ</label>
                    <LinkButton className={"media-select-btn bg-white"}
                                title={"Ընտրել File ֆայլ"}
                                cb={this.toggleFileModal}/>
                </div>
                <div className={'contract-wrapper'}>
                    <div className={'wrapper'}>
                        <span>Անվանում՝ {fieldsData.translations[languageTab]?.presentationFile?.name || initTranslations?.presentationFile?.name}</span>
                    </div>
                </div>
                <InputGroup inputType="input"
                            type="text"
                            label={'Հասցե'}
                            name="address"
                            maxLength={100}
                            value={trData?.address}
                            required={true}
                            initValue={initTranslations?.address}
                            error={errors['address' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label={'Վերնագիր'}
                            name="title"
                            maxLength={100}
                            value={trData?.title}
                            required={true}
                            initValue={initTranslations?.title}
                            error={errors['title' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label={'Ենթավերնագիր'}
                            name="description"
                            maxLength={100}
                            value={trData?.description}
                            required={true}
                            initValue={initTranslations?.description}
                            error={errors['description' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                loading={requestLoading}
                                cb={this.updateContact}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!pdfModalOpen}
                acceptTypes={['pdf']}
                getMedia={this.getPdf}
                appendMedias={fieldsData.pdf}
                closeModal={this.togglePdfModal}
            />
            <MediaSelectorModal
                isOpen={!!fileModalOpen}
                acceptTypes={['pdf']}
                getMedia={this.getFile}
                appendMedias={fieldsData.presentationFile}
                closeModal={this.toggleFileModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetContact,
    UpdateContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);


/*
*
* {
  "name": "shat-admin",
  "version": "0.1.0",
  "private": true,
  "dependencies": {
    "@testing-library/jest-dom": "^5.1.1",
    "@testing-library/react": "^9.5.0",
    "@testing-library/user-event": "^10.0.0",
    "antd": "^4.8.6",
    "axios": "^0.19.2",
    "babel-plugin-import": "^1.13.0",
    "customize-cra": "^0.9.1",
    "history": "^4.10.1",
    "less": "^3.11.1",
    "less-loader": "5.0.0",
    "material-icons": "^0.3.1",
    "moment": "^2.24.0",
    "node-sass": "^4.14.1",
    "qrcode.react": "^1.0.0",
    "query-string": "^6.11.1",
    "react": "^16.13.1",
    "react-app-rewired": "^2.1.5",
    "react-color": "^2.18.0",
    "react-copy-to-clipboard": "^5.0.2",
    "react-data-export": "^0.6.0",
    "react-dom": "^16.13.1",
    "react-easy-crop": "^3.0.1",
    "react-infinite-scroller": "^1.2.4",
    "react-modal": "^3.11.2",
    "react-nestable": "^1.3.0",
    "react-redux": "^7.2.0",
    "react-router-dom": "^5.1.2",
    "react-scripts": "^3.4.1",
    "redux": "^4.0.5",
    "redux-persist": "^6.0.0",
    "redux-thunk": "^2.3.0",
    "suneditor-react": "^2.11.1",
    "sweetalert": "^2.1.2",
    "validator": "^12.2.0",
    "xlsx": "^0.16.0"
  },
  "scripts": {
    "start": "set PORT=3501 && react-app-rewired start",
    "build": "react-app-rewired build",
    "test": "react-scripts test",
    "eject": "react-scripts eject"
  },
  "eslintConfig": {
    "extends": "react-app"
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "redux-devtools-extension": "^2.13.8"
  }
}

* */