export const StaticTextFields = {
    header: {
        pageName: 'Header',
        list: [
            {
                label: "Header - home",
                key: "header_item_home"
            },
            {
                label: "Header - services",
                key: "header_item_services"
            },
            {
                label: "Header - about",
                key: "header_item_about"
            },
            {
                label: "Header - work",
                key: "header_item_work"
            },
            {
                label: "Header - partner",
                key: "header_item_staff"
            },
            {
                label: "Header - contact",
                key: "header_item_contact"
            },
        ]
    },
    homepage: {
        pageName: 'Homepage',
        list: [
            {
                label: "Homepage - main title",
                key: "homepage_main_block_title"
            },
            {
                label: "Homepage - main description",
                key: "homepage_main_block_description"
            },
            {
                label: "Homepage - main btn title",
                key: "homepage_main_block_btn"
            },
            {
                label: "Homepage - slider block title",
                key: "homepage_slider_block_title"
            },
            {
                label: "Homepage - slider block description",
                key: "homepage_slider_block_description"
            },
            {
                label: "Homepage - security block title",
                key: "homepage_security_block_title"
            },
            {
                label: "Homepage - partners block subtitle",
                key: "homepage_partners_block_subtitle"
            },
            {
                label: "Homepage - partners block title",
                key: "homepage_partners_block_title"
            },
            {
                label: "Homepage - services title",
                key: "homepage_services_title"
            },
            {
                label: "Homepage - services subtitle",
                key: "homepage_services_subtitle"
            },
            {
                label: "Homepage - services description",
                key: "homepage_services_description"
            },
            {
                label: "Homepage - services attention",
                key: "homepage_services_attention"
            },
            {
                label: "Homepage -services btn",
                key: "homepage_services_btn"
            },
            {
                label: "Homepage - more info btn",
                key: "homepage_more_info_btn"
            },
            {
                label: "Homepage - projects title",
                key: "homepage_projects_title"
            },
            {
                label: "Homepage - projects subtitle",
                key: "homepage_projects_subtitle"
            },
            {
                label: "Homepage - projects description",
                key: "homepage_projects_description"
            },
            {
                label: "Homepage - contact2 subtitle",
                key: "homepage_contact2_subtitle"
            },
            {
                label: "Homepage - contact2 title",
                key: "homepage_contact2_title"
            },
            {
                label: "Homepage - contact2 description",
                key: "homepage_contact2_description"
            },
            {
                label: "Homepage - contact2 download title",
                key: "homepage_contact2_download_title"
            },
            {
                label: "Homepage - contact2 download info",
                key: "homepage_contact2_download_info"
            },
            {
                label: "Homepage - contact2 button send",
                key: "homepage_contact2_button_send"
            },
            {
                label: "Homepage - contact2 text",
                key: "contact2_text"
            },
        ]


    },
    services:{
      pageName:"Services",
      list:[
          {
              label:"Service - blocks title",
              key:"service_blocks_title"
          },
          {
              label:"Service - blocks description",
              key:"service_blocks_description"
          },
          {
              label:"Service - feathers title",
              key:"service_feathers_title"
          },
          {
              label:"Service - feathers description",
              key:"service_feathers_description"
          },
          {
              label:"Service - sponsors title",
              key:"service_sponsors_title"
          },
          {
              label:"Service - consultation title",
              key:"service_consultation_title"
          },
          {
              label:"Service - consultation description",
              key:"service_consultation_description"
          },
          {
              label:"Service - systems blocks title",
              key:"systems_blocks_title"
          },
      ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - section sites title",
                key: "footer_section_sites_title"
            },
            {
                label: "Footer - section home",
                key: "footer_section_home"
            },
            {
                label: "Footer - section services",
                key: "footer_section_services"
            },
            {
                label: "Footer - section about",
                key: "footer_section_about"
            },
            {
                label: "Footer - section news",
                key: "footer_section_news"
            },
            {
                label: "Footer - section staff",
                key: "footer_section_staff"
            },
            {
                label: "Footer - section contact",
                key: "footer_section_contact"
            },
            {
                label: "Footer - section services title",
                key: "footer_section_services_title"
            },
            {
                label: "Footer - section correspond",
                key: "footer_section_correspond"
            },
            {
                label: "Footer - section number",
                key: "footer_section_number"
            },
            {
                label: "Footer - section call",
                key: "footer_section_call"
            },
            {
                label: "Footer - section projects title",
                key: "footer_section_projects_title"
            },
            {
                label: "Footer - section Smartlife.am",
                key: "footer_section_MobileID"
            },
            {
                label: "Footer - section Sos911.am",
                key: "footer_section_Fortune"
            },
            {
                label: "Footer - section Login",
                key: "footer_section_Login"
            },
            {
                label: "Footer - section Promos",
                key: "footer_section_Promos"
            },
            {
                label: "Footer - section copyright text",
                key: "footer_section_copyright_text"
            },
            {
                label: "Footer - section developed by text",
                key: "footer_section_developed_by_text"
            },
            {
                label: "Footer - download presentation",
                key: "footer_download_presentation"
            },
        ]
    },
    blog: {
        pageName: 'Blog',
        list: [
            {
                label: "Blog - Page prev",
                key: "blog_page_prev"
            },
            {
                label: "Blog - Page next",
                key: "blog_page_next"
            },
            {
                label: "Blog - Page subscribe subtitle",
                key: "blog_page_subscribe_subtitle"
            },
            {
                label: "Blog - Page subscribe title",
                key: "blog_page_subscribe_title"
            },
            {
                label: "Blog - Page subscribe description",
                key: "blog_page_subscribe_description"
            },
            {
                label: "Blog - Page last news",
                key: "blog_page_last_news_title"
            },
            {
                label: "Blog - Page all news",
                key: "blog_page_all_news_title"
            },
            {
                label: "Blog - Page data title",
                key: "blog_pageDetail_data_title"
            },
        ]
    },
    pages: {
        pageName: 'Some Pages',
        list: [
            {
                label: "About - Page count projects",
                key: "about_page_count_projects"
            },
            {
                label: "About - Page count commercials",
                key: "about_page_count_commercials"
            },
            {
                label: "About - Page count houses",
                key: "about_page_count_houses"
            },
            {
                label: "About - Page count workers",
                key: "about_page_count_workers"
            },
            {
                label: "About - Page count contact",
                key: "about_page_btn_contact"
            },
            {
                label: "About - Page count houses",
                key: "about_page_btn_houses"
            },
            {
                label: "Partners - Page subTitle",
                key: "partners_page_subTitle"
            },
            {
                label: "Partners - Page title",
                key: "partners_page_title"
            },
            {
                label: "Partners - Page description",
                key: "partners_page_description"
            },
            {
                label: "Partners - Page contact subTitle",
                key: "partners_page_contact_subTitle"
            },
            {
                label: "Partners - Page contact title",
                key: "partners_page_contact_title"
            },
            {
                label: "Partners - Page contact description",
                key: "partners_page_contact_description"
            },
            {
                label: "Partners - Page contact btn",
                key: "partners_page_contact_btn"
            },
            {
                label: "Subscribe - title",
                key: "subscribe_title"
            },
            {
                label: "Subscribe - description",
                key: "subscribe_description"
            },
            {
                label: "Subscribe - btn",
                key: "subscribe_btn"
            },
            {
                label: "Work - Page subTitle",
                key: "work_page_subTitle"
            },
            {
                label: "Work - Page title",
                key: "work_page_title"
            },
            {
                label: "Work - Page description",
                key: "work_page_description"
            },
        ]
    },
    contacts: {
        pageName: 'Contacts',
        list: [
            {
                label: "Contacts - Page title",
                key: "contacts_page_title"
            },
            {
                label: "Contacts - Page placeholder name",
                key: "contacts_page_placeholder_name"
            },
            {
                label: "Contacts - Page label name",
                key: "contacts_page_label_name"
            },
            {
                label: "Contacts - Page placeholder lastName",
                key: "contacts_page_placeholder_lastName"
            },
            {
                label: "Contacts - Page label lastName",
                key: "contacts_page_label_lastName"
            },
            {
                label: "Contacts - Page placeholder email",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "Contacts - Page label email",
                key: "contacts_page_label_email"
            },
            {
                label: "Contacts - Page placeholder phoneNumber",
                key: "contacts_page_placeholder_phoneNumber"
            },
            {
                label: "Contacts - Page label phoneNumber",
                key: "contacts_page_label_phoneNumber"
            },
            {
                label: "Contacts - Page placeholder message",
                key: "contacts_page_placeholder_message"
            },
            {
                label: "Contacts - Page label message",
                key: "contacts_page_label_message"
            },
            {
                label: "Contacts - Page btn send",
                key: "contacts_page_btn_send"
            },
            {
                label: "Contacts - Page error",
                key: "contacts_page_error"
            },
            {
                label: "Contacts - Page bloke1 title",
                key: "contacts_page_info_bloke1_title"
            },
            {
                label: "Contacts - Page bloke1 description",
                key: "contacts_page_info_bloke1_description"
            },
            {
                label: "Contacts - Page bloke2 title",
                key: "contacts_page_info_bloke2_title"
            },
            {
                label: "Contacts - Page bloke2 description",
                key: "contacts_page_info_bloke2_description"
            },
            {
                label: "Contacts - Page bloke3 title",
                key: "contacts_page_info_bloke3_title"
            },
            {
                label: "Contacts - Page bloke3 description",
                key: "contacts_page_info_bloke3_description"
            },
        ]
    },
    contactModal:{
        pageName:"Contact Modal",
        list:[
            {
                label: "Contact modal - placeholder name",
                key: "contactModal_placeholder_name"
            },
            {
                label: "Contact modal - label name",
                key: "contactModal_label_name"
            },
            {
                label: "Contact modal - placeholder resident address",
                key: "contactModal_placeholder_resAddress"
            },
            {
                label: "Contact modal - label resident address",
                key: "contactModal_label_resAddress"
            },
            {
                label: "Contact modal - placeholder bank",
                key: "contactModal_placeholder_bank"
            },
            {
                label: "Contact modal - label bank",
                key: "contactModal_label_bank"
            },
            {
                label: "Contact modal - placeholder email",
                key: "contactModal_placeholder_email"
            },
            {
                label: "Contact modal - label email",
                key: "contactModal_label_email"
            },
            {
                label: "Contact modal - placeholder address",
                key: "contactModal_placeholder_address"
            },
            {
                label: "Contact modal - label address",
                key: "contactModal_label_address"
            },
            {
                label: "Contact modal - placeholder TSS",
                key: "contactModal_placeholder_tss"
            },
            {
                label: "Contact modal - label TSS",
                key: "contactModal_label_tss"
            },
            {
                label: "Contact modal - placeholder Full Name",
                key: "contactModal_placeholder_fullName"
            },
            {
                label: "Contact modal - label Full Name",
                key: "contactModal_label_fullName"
            },
            {
                label: "Contact modal - placeholder phone",
                key: "contactModal_placeholder_phone"
            },
            {
                label: "Contact modal - label phone",
                key: "contactModal_label_phone"
            },
            {
                label: "Contact modal - subtitle",
                key: "contactModal_subtitle"
            },
            {
                label: "Contact modal - ok btn text",
                key: "contactModal_ok_btn"
            },
            {
                label: "Contact modal - cancel btn text",
                key: "contactModal_cancel_btn"
            },
            {
                label: "Contact modal - radio btn title",
                key: "contactModal_radio_btn_title"
            },
            {
                label: "Contact modal - radio btn text1",
                key: "contactModal_radio_btn_text1"
            },
            {
                label: "Contact modal - radio btn text2",
                key: "contactModal_radio_btn_text2"
            },
            {
                label: "Contact modal - second radio btn title",
                key: "contactModal_radio2_btn_title"
            },
            {
                label: "Contact modal - second radio btn text1",
                key: "contactModal_radio2_btn_text1"
            },
            {
                label: "Contact modal - second radio btn text2",
                key: "contactModal_radio2_btn_text2"
            },
            {
                label: "Contact modal - checkout text",
                key: "contactModal_checkout_text"
            },
            {
                label: "Contact modal - download text",
                key: "contactModal_download_text"
            },
        ]
    }
}