export const ACCESS_PAGES = {
    moderators: {
        key: 'MODERATORS',
        name: 'Մոդերատորներ',
        icon: 'supervised_user_circle',
        crud: false,
        isHidden: true,
    },
    media: {
        key: 'FILES',
        name: 'Ֆայլեր',
        icon: 'filter',
        crud: true,
        isHidden: false,
    },
    blocks: {
        key: 'BLOCKS',
        name: 'Բլոկներ',
        icon: 'dashboard',
        crud: true,
        isHidden: true,
    },
    slider: {
        key: 'SLIDER',
        name: 'Սլայդերը',
        icon: 'amp_stories',
        crud: true,
        isHidden: false,
    },
    project: {
        key: 'PROJECTS',
        name: 'Նախագծեր',
        icon: 'description',
        crud: true,
        isHidden: false,
    },
    sponsors: {
        key: 'SPONSORS',
        name: 'Հովանավորներ',
        icon: 'folder_shared',
        crud: true,
        isHidden: false,
    },
    partners: {
        key: 'PARTNERS',
        name: 'Գործընկերներ',
        icon: 'group_work',
        crud: true,
        isHidden: false,
    },
    subscribers: {
        key: 'SUBSCRIBERS',
        name: 'Բաժանորդներ',
        icon: 'supervisor_account',
        crud: true,
        isHidden: false,
    },
    about: {
        key: 'ABOUT',
        name: 'Մեր Մասին',
        icon: 'category',
        crud: true,
        isHidden: false,
    },
    services: {
        key: 'SERVICES',
        name: 'Ծառայություններ',
        icon: 'settings',
        crud: true,
        isHidden: false,
    },
    works: {
        key: 'WORKS',
        name: 'Աշխատանքներ',
        icon: 'works',
        crud: true,
        isHidden: false,
    },
    languages: {
        key: 'LANGUAGES',
        name: 'Լեզուներ',
        icon: 'language',
        crud: true,
        isHidden: false,
    },
    contact: {
        key: 'CONTACTS',
        name: 'Կապ',
        icon: 'contact_phone',
        crud: true,
        isHidden: false,
    },
    staticTexts: {
        key: 'STATIC_TEXTS',
        name: 'Թարգմանություններ',
        icon: 'text_format',
        crud: true,
        isHidden: false,
    },
};
