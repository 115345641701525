// Import packages
import React from "react";
import {Route, Switch, Router, Redirect} from "react-router-dom";

// Import utils
import {history} from "../configs/history";
import {ProtectedRoute} from "./protectedRoute";

// Import pages
import AdminLayout from '../containers/AdminLayout';
import Login from '../containers/Login';

import NotFound from '../containers/NotFound';
import Contact from '../containers/Contact';

import Moderators from '../containers/Moderators/Moderators';
import AddEditModerator from "../containers/Moderators/AddEditModerator";

import Media from "../containers/media/Media";
import EditFile from "../containers/media/EditFile";

import Languages from "../containers/languages/Languages";
import EditLanguage from "../containers/languages/EditLanguage";
import Partner from "../containers/partners/Partner";
import AddEditPartner from "../containers/partners/AddEditPartner";
import StaticTexts from "../containers/staticTexts/StaticTexts";
import Subscribers from "../containers/subscribers/Subscribers";
import About from "../containers/about/About";
import Services from "../containers/services/Services";
import AddEditService from "../containers/services/AddEditService";
import Works from "../containers/works/Works";
import AddEditWork from "../containers/works/AddEditWork";
import Project from "../containers/project/Project";
import AddEditProject from "../containers/project/AddEditProject";
import Blocks from "../containers/blocks/Blocks";
import EditBlocks from "../containers/blocks/EditBlocks";
import Slider from "../containers/slider/Slider";
import AddEditSlider from "../containers/slider/AddEditSlider";
import Sponsors from "../containers/sponsors/Sponsors";
import AddEditSponsors from "../containers/sponsors/AddEditSponsors";
import AddEditAbout from "../containers/about/AddEditAbout";


export default () => {

    return <Router history={history}>
        <Switch>
            <Redirect exact from="/" to="/login"/>
            <Route exact path="/login" component={Login}/>
            <AdminLayout>
                <Switch>
                    <Route exact path="/dashboard" component={Blocks}/>

                    <ProtectedRoute exact path="/dashboard/edit/:id" component={EditBlocks}/>

                    <ProtectedRoute exact path="/moderators" component={Moderators}/>
                    <ProtectedRoute exact path="/moderators/add" component={AddEditModerator}/>
                    <ProtectedRoute exact path="/moderators/edit/:id" component={AddEditModerator}/>

                    <ProtectedRoute exact path="/slider" component={Slider}/>
                    <ProtectedRoute exact path="/slider/add" component={AddEditSlider}/>
                    <ProtectedRoute exact path="/slider/edit/:id" component={AddEditSlider}/>

                    <ProtectedRoute exact path="/media" component={Media}/>
                    <ProtectedRoute exact path="/media/edit/:id" component={EditFile}/>

                    <ProtectedRoute exact path="/partners" component={Partner}/>
                    <ProtectedRoute exact path="/partners/add" component={AddEditPartner}/>
                    <ProtectedRoute exact path="/partners/edit/:id" component={AddEditPartner}/>

                    <ProtectedRoute exact path="/project" component={Project}/>
                    <ProtectedRoute exact path="/project/add" component={AddEditProject}/>
                    <ProtectedRoute exact path="/project/edit/:id" component={AddEditProject}/>

                    <ProtectedRoute exact path="/sponsors" component={Sponsors}/>
                    <ProtectedRoute exact path="/sponsors/add" component={AddEditSponsors}/>
                    <ProtectedRoute exact path="/sponsors/edit/:id" component={AddEditSponsors}/>

                    <ProtectedRoute exact path="/subscribers" component={Subscribers}/>

                    <ProtectedRoute exact path="/about" component={About}/>
                    <ProtectedRoute exact path="/about/edit/:id" component={AddEditAbout}/>
                    <ProtectedRoute exact path="/about/add" component={AddEditAbout}/>

                    <ProtectedRoute exact path="/services" component={Services}/>
                    <ProtectedRoute exact path="/services/add" component={AddEditService}/>
                    <ProtectedRoute exact path="/services/edit/:id" component={AddEditService}/>

                    <ProtectedRoute exact path="/works" component={Works}/>
                    <ProtectedRoute exact path="/works/add" component={AddEditWork}/>
                    <ProtectedRoute exact path="/works/edit/:id" component={AddEditWork}/>

                    <ProtectedRoute exact path="/languages" component={Languages}/>
                    <ProtectedRoute exact path="/languages/edit/:id" component={EditLanguage}/>

                    <ProtectedRoute exact path="/contact" component={Contact}/>
                    <ProtectedRoute exact path="/staticTexts" component={StaticTexts}/>

                    <Route exact path="/404" component={NotFound}/>
                    <Route component={NotFound}/>
                </Switch>
            </AdminLayout>
        </Switch>
    </Router>
}
