import { SERVICES_CONSTS } from "../constants";

export const initialState = {
    servicesData: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case SERVICES_CONSTS.SET_SERVICES_DATA:
            return {
                ...state,
                servicesData: action.payload ,
            };
        default:
            return state;
    }
}
