export const ContactAddingList = [
    {
        label: "Էլ․ հասցե",
        inputType: "input",
        type: "text",
        placeholder: "Էլ․ հասցե",
        name: "email",
        maxLength: 500
    },
    {
        label: "Հեռախոս",
        inputType: "input",
        type: "text",
        regExp:/^[+\d]\d*$/,
        placeholder: "Հեռախոս",
        name: "phoneNumber",
        maxLength: 20
    },
    {
        label: "Երկրորդ հեռախոսահամար",
        inputType: "input",
        type: "text",
        regExp:/^[+\d]\d*$/,
        placeholder: "Երկրորդ հեռախոսահամար",
        name: "phoneNumber2",
        maxLength: 20
    },
];
