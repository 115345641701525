import {_urlPartner, _urlSliders, request} from "../api";
import {PARTNER_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";

export const GetPartnersData = () => {
    const requestData = {
        url: _urlPartner,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: PARTNER_CONSTS.SET_PARTNERS_DATA,
                    payload: data
                });
            })
    }
};

export const GetPartnerById = (id) => {
    const requestData = {
        url: `${_urlPartner}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: PARTNER_CONSTS.GET_PARTNERS_BY_ID,
                    payload: data
                });
            })
    }
};

export const UpdatePartnersById = (id, data) => {
    const requestData = {
        url: `${_urlPartner}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: PARTNER_CONSTS.UPDATE_PARTNER_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Գործընկերը հաջողությամբ փոփոխվեց!"
                })
                history.push('/partners')
            })
            .catch(() => {
            })
    }
};

export const DeletePartnerById = id => {
    const requestData = {
        url:  `${_urlPartner}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PARTNER_CONSTS.DELETE_PARTNER_ITEM,
                    payload: id
                });
            })
            .catch(() => {})
    }
};

export const CreatePartner = data => {
    const requestData = {
        url: _urlPartner,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: PARTNER_CONSTS.CREATE_PARTNER_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Գործընկերը հաջողությամբ ստեղծվեց!"
                })
                history.push('/partners')
            })
            .catch(() => {
            })
    }
};

export const UpdatePartnersPositions = items => {
    const requestData = {
        url: _urlPartner,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PARTNER_CONSTS.UPDATE_PARTNERS_POSITIONS
                });
                notificationSuccess({
                    description: "Գործընկերը հաջողությամբ թարմացվեց!"
                })
            })
            .catch(() => {
            })
    }
};