// Import packages
import React, {Component} from "react";
import {connect} from "react-redux"

//import styles

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetBlocksById, UpdateBlocksById} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {Editor} from "../../components/uiElements/Editor";

class AddEditBlock extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                icon: undefined,
                hoverIcon: null,
            },
            errors: {},
            errorsTabs: [],
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.toggleHoverMediaModal = toggleStateField.bind(this, 'mediaHoverModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getHoverMedia = this.getHoverMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.deleteHoverMedia = this.deleteHoverMedia.bind(this);
        this.addEditBlock = this.addEditBlock.bind(this);

    }

    async componentDidMount() {
        const {id} = this.props;
        const {fieldsData} = this.state;
        await this.props.GetBlocksById(id)

        const translationsData = {};
        const editingData = this.props.blocksById

        if (editingData) {
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = editingData.translations.find(item => item.languageId === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                };
            });
        }
        const initStateData = {
            translations: translationsData,
            icon: editingData?.icon,
            hoverIcon: editingData?.hoverIcon,
        };

        this.currentData = JSON.parse(JSON.stringify(initStateData));
        this.setState({
            fieldsData: initStateData,
        });
    }

    getMedia(mediaArray) {
        const {fieldsData, errors} = this.state;
            this.currentData?.icon?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('icon', mediaArray[0]?.id)
                : this.updatedDataMap.delete('icon');

        this.setState({
            fieldsData: {
                ...fieldsData,
                icon: mediaArray[0],
            },
            errors: {
                ...errors,
                icon: false
            }
        })
    }

    getHoverMedia(mediaArray) {
        const {fieldsData, errors} = this.state;
            this.currentData?.hoverIcon?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('hoverIcon', mediaArray[0]?.id)
                : this.updatedDataMap.delete('hoverIcon');

        this.setState({
            fieldsData: {
                ...fieldsData,
                hoverIcon: mediaArray[0],
            },
            errors: {
                ...errors,
                hoverIcon: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData} = this.state;
            this.currentData?.icon ?
                this.updatedDataMap.set("icon", null) :
                this.updatedDataMap.delete("icon");

        this.setState({
            fieldsData: {
                ...fieldsData,
                icon: null
            }
        })
    }

    deleteHoverMedia() {
        const {fieldsData} = this.state;
            this.currentData?.hoverIcon ?
                this.updatedDataMap.set("hoverIcon", null) :
                this.updatedDataMap.delete("hoverIcon");

        this.setState({
            fieldsData: {
                ...fieldsData,
                hoverIcon: null
            }
        })
    }


    async addEditBlock() {
        const {id} = this.props;
        await this.setState({
            requestLoading: true
        });
        const {translations, icon,hoverIcon} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!icon) {
            errors.icon = true;
            result = false;
        }

        if (result) {
            {
                const trData = getTranslationData(translations);
                const reqData = {
                    icon:icon?.id,
                    hoverIcon:hoverIcon?.id || null,
                    translations:trData
                }
                this.props.UpdateBlocksById(id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, mediaModalOpen,mediaHoverModalOpen, requestLoading, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={'Փոփոխել Բլոկը'}>
            <section className="general-add-edit">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.icon ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.icon && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.icon}
                                   customDelete={this.deleteMedia}
                    />
                </div>}

                <div className="top-side">
                    <label>Hover Նկար </label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.hoverIcon ? 'media-select-error' : ''}`}
                                cb={this.toggleHoverMediaModal}
                    />
                </div>
                {fieldsData?.hoverIcon && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.hoverIcon}
                                   customDelete={this.deleteHoverMedia}
                    />
                </div>}

                <div className="info-wrapper">
                    <div className={'type-block'}>
                        Տիպ: <span>{this.props.blocksById?.type}</span>
                    </div>
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>

                    <label>Նկարագրություն<span className={'required-badge'}>*</span></label>
                    <Editor value={currentData?.description}
                            name={'description'}
                            initValue={initData.description}
                            error={errors['description' + languageTab]}
                            onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                cb={this.addEditBlock}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
            <MediaSelectorModal
                isOpen={!!mediaHoverModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getHoverMedia}
                closeModal={this.toggleHoverMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetBlocksById,
    UpdateBlocksById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBlock)
