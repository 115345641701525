import {BLOCKS_CONSTS, PROJECT_CONSTS, SLIDER_CONSTS} from "../constants";

export const initialState = {
    blocksList: [],
    blocksById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BLOCKS_CONSTS.BLOCKS_GET:
            return {
                ...state,
                blocksList: action.payload || [],
            };
        case BLOCKS_CONSTS.BLOCKS_GET_BY_ID:
            return {
                ...state,
                blocksById:action.payload || [],
            };
        default:
            return state;
    }
}
