// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetBlocks} from "../../redux/actions";


class Blocks extends Component {


    componentDidMount() {
        this.props.GetBlocks();
    }

    render() {
        const {blocksList,mainLanguage} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անուն"],
            largeItems: [0],
            actions: ["edit"],
        };
        let itemListInfo = [];
        blocksList && !!blocksList.length && blocksList.forEach(block => {
            const trData = block?.translations?.find(tr => tr.languageId === mainLanguage);
            itemListInfo.push({
                id: block.id,
                values: [trData.title],
            })
        });

        return <PageWrapper pageTitle={'Բլոկներ'}>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       editLink={"/dashboard/edit"}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetBlocks,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blocks);
